<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_incoming_mail')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body v-if="$can('edit', PREFIX+'.tab_details')">
            <b-card-header class="pb-50">
                <h5 >
                    {{$t('label_incoming_mail')}}
                    <!--{{ // $t('label_filters') }}-->
                </h5>
            </b-card-header>
            <b-card-body class="pb-0">
                <b-row>


                    <b-col
                            cols="12"
                            md="12"
                            class="mb-md-0  d-flex align-items-center"
                    >


                        <b-button variant="outline-primary" v-if="$can('edit', PREFIX+'.tab_details')" :to="{ name: 'mail_add' }" class="mr-1 mb-1">
                            <feather-icon
                                    icon="CheckIcon"
                                    class="mr-50"
                            />
                            <span>{{$t('label_add_mail')}}</span>
                        </b-button>
                        <b-button variant="outline-primary" @click="$bvModal.show(PARTICIPANT_PREFIX+'-modal')" v-if="$can('edit', PREFIX+'.tab_details')" class="mr-1 mb-1" >
                            <feather-icon
                                    icon="UserPlusIcon"
                                    class="mr-50"
                            />
                            <span>{{$t('label_add_to_recipient_database')}}</span>
                        </b-button>

                    </b-col>

                    <b-col cols="12">
                        <b-row class="pb-1">
                            <b-col
                                    cols="12"
                                    md="3"
                                    class=" date-range-col"
                            >
                                <label>{{ $t('label_receiving_date') }}</label>

                                <div class="date-range-filter mb-1">
                                    <custom-date-picker
                                            class="date--picker no-label"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="filterData.sort_by_date_from"
                                            @input="(val) => {filterData.sort_by_date_from = val}"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                    </custom-date-picker>

                                    <div class="date-separator"/>
                                    <custom-date-picker
                                            class="date--picker no-label"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="filterData.sort_by_date_to"
                                            @input="(val) => {filterData.sort_by_date_to = val}"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                    </custom-date-picker>

                                </div>
                                <label>{{$t('label_deadline_reply')}}</label>
                                <div class="date-range-filter mb-1">
                                    <custom-date-picker
                                            class="date--picker no-label"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="filterData.deadline_reply_at_from"
                                            @input="(val) => {filterData.deadline_reply_at_from = val}"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                    </custom-date-picker>

                                    <div class="date-separator"/>
                                    <custom-date-picker
                                            class="date--picker no-label"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"

                                            :value="filterData.deadline_reply_at_to"
                                            @input="(val) => {filterData.deadline_reply_at_to = val}"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                    </custom-date-picker>

                                </div>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_responsible_person')}}</label>
                                <infinity-scroll
                                        selected-prop="id"
                                        :multiple="false"
                                        url="/user"
                                        :placeholder="$t('label_select')"
                                        v-model="filterData.responsible_user_id"
                                >
                                    <template #label="{item}">{{ item.name }}</template>

                                </infinity-scroll>

                            </b-col>
                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_agreement')+ ' / ' + $t('label_deal')}}</label>
                                <infinity-scroll

                                        selected-prop="id"
                                        :multiple="false"
                                        url="/agreement"
                                        :placeholder="$t('label_select')"
                                        v-model="filterData.agreement_id"

                                >
                                    <!--:default-selection="chosenAgreement"-->
                                    <template #label="{item}">{{ item.deal_number }}</template>

                                </infinity-scroll>

                            </b-col>
                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_shipment_type')}}</label>
                                <v-select
                                        style="max-width: 300px"
                                        class="sort--select"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="filterData.shipment_type_id"
                                        :options="shipmentTypes"

                                        label="name"
                                        :placeholder="$t('label_shipment_type')"
                                        :reduce="val => val.id"

                                >
                                    <template v-slot:option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{ $t(option.translation_index) }}</span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        {{ $t(option.translation_index)}}
                                    </template>
                                    <template v-slot:no-options="option">
                                        {{$t('label_no_items')}}
                                    </template>
                                </v-select>
                            </b-col>

                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card
                no-body
                class="mb-0"
        >

            <div class="m-2">

                <b-row>

                    <b-col
                            cols="12"
                            md="5"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <v-select
                                v-model="tableData.perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                :searchable="false"
                                class="per-page-selector d-inline-block mx-50"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                    </b-col>

                    <b-col
                            cols="12"
                            md="5"
                            class="ml-auto d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <b-form-input
                                v-model="tableData.searchQuery"
                                type="search"
                                debounce="500"
                                class="d-inline-block mr-1 "
                                :placeholder="$t('label_search')+'...'"
                        />


                    </b-col>
                </b-row>

            </div>

            <!--***********************************************************TABLE*********************************************-->

            <div class="px-2 table-container-wrap">
                <!--sticky-header-->
                <b-table
                        ref="table"
                        class="position-relative transited-table"
                        :busy="isBusy"
                        no-border-collapse
                        :items="tableItems"
                        responsive
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"

                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>

                    <template #cell(deal_number)="data">
                        <template v-if="data.item.agreement">
                            <router-link v-if="data.item.agreement.deal && $can('view', 'deal.tab_details')"  :to="{ name: 'deal-details', params: { id: data.item.agreement.deal.id } }">
                                {{ data.item.agreement.deal_number }}
                            </router-link>
                            <router-link v-else-if="$can('view', 'agreement.tab_details') || $can('view', 'agreement.tab_details')"  :to="{ name: 'agreement-details', params: { id: data.item.agreement.id } }">
                                {{ data.item.agreement.deal_number }}
                            </router-link>
                            <template v-else >
                                {{ data.item.agreement.deal_number }}
                            </template>
                        </template>
                        <span v-else>--</span>
                    </template>

                    <template #cell(user_name)="data">
                        {{data.item.user.name}}
                    </template>
                    <template #cell(shipment_type)="data">
                        {{data.item.shipment_type? $t(data.item.shipment_type.translation_index) : ''}}
                    </template>

                    <template #cell(document)="data">
                        <a v-b-tooltip.hover.top="getFileName(data.item.documents[0])" v-if="data.item.documents[0]" @click="previewFile($event,  'mail/download/', Object.assign(data.item.documents[0], {id:data.item.id}))" href="#"> <feather-icon icon="FileIcon"></feather-icon> </a>
                    </template>

                    <template #cell(description)="data">
                        <div v-b-tooltip.hover.top="data.item.description" class="one-line-limit">{{data.item.description}}</div>
                    </template>

                    <template #cell(responsible_user)="data">
                        <div  class="one-line-limit">{{data.item.responsible_user? data.item.responsible_user.name : '--'}}</div>
                    </template>

                    <template #cell(addressee)="data">
                        <div style="min-width: 120px" v-if="data.item.with_recipient == 1"> {{data.item.recipient_name}}</div>
                       <span v-else>--</span>
                    </template>



                    <!-- Column: Actions -->
                    <template #cell(actions)="data">
                        <b-dropdown
                                dropleft
                                boundary="window"
                                variant="link"
                                no-caret
                                @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                                @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                                :right="$store.state.appConfig.isRTL"
                        >

                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>

                            <b-dropdown-item disabled>
                                <div class="divider">
                                    <div class="divider-text">{{ $t('label_action') }}</div>
                                </div>
                            </b-dropdown-item>

                            <b-dropdown-item :to="{name: 'mail_edit', params: { id: data.item.id }}">
                                <feather-icon icon="EditIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="deleteItem(data.item.id)">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                            </b-dropdown-item>


                        </b-dropdown>
                    </template>

                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>


        </b-card>

        <process-member-modal></process-member-modal>

    </div>
</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,  VBTooltip,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import processMemberModal from './../mail/modals/processMemberModal'
    import infinityScroll from '@/views/components/infinityScroll'

    import {MAIL_PREFIX as PREFIX, PARTICIPANT_PREFIX} from './moduleHelper'

    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            vSelect,
            processMemberModal,
            infinityScroll
        },

        data() {
            return {
                PARTICIPANT_PREFIX,
                PREFIX,
                editedItem: false,
                isSidebarOpen: false,
                table_name: 'table-contacts',

                checkedIds: [],

                selectedColumns: [],
                shipmentTypes: [],

                columnsDefs: [

                    {
                        label: 'label_receiving_date', key: 'receiving_at', sortable: false,
                        formatter: function (v, k, item) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix')
                        }
                    },
                    {label: 'label_description', key: 'description', sortable: false },
                    {label: 'label_tracking_number', key: 'tracking_number', sortable: false},
                    {label: 'label_deal_number', key: 'deal_number', sortable: false},
                    {label: 'label_sender', key: 'sender_name'},
                    {label: 'label_addressee', key: 'addressee'},
                    {label: 'label_shipment_type', key: 'shipment_type'},
                    {
                        label: 'label_deadline_reply', key: 'deadline_reply_at', sortable: false,
                        formatter: function (v, k, item) {
                            return v? formatDate(v, 'DD.MM.YYYY', 'unix') : '--';
                        }
                    },
                    {label: 'label_user', key: 'user_name'},
                    {label: 'label_document', key: 'document'},
                    {label: 'label_responsible_person', key: 'responsible_user'},
                    {label: 'label_action', key: 'actions'},

                ],

                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },

                filterData: {
                    responsible_user_id: '',
                    agreement_id: '',
                    sort_by_date_from: '',
                    sort_by_date_to: '',
                    shipment_type_id: null,
                    deadline_reply_at_from: null,
                    deadline_reply_at_to: null,
                },


            }
        },
        directives: {
            'b-tooltip': VBTooltip,
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.getPageData();

            this.columnsDefs = this.columnsDefs.filter((el)=>{
                if(el.key == 'actions' && !this.$can('edit', 'mail.tab_details')){
                    return false;
                }
                return true;
            });
            this.refreshDataTable();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            getPageData() {

                this.async('get', '/select_options', {params:{options:['mail_shipment_types']}}, function(res){
                    this.shipmentTypes = res.data.mail_shipment_types;
                });
            },

            changeDealStatus(module_id, status_alias) {
                this.async('post', '/' + this.PREFIX + '/change_status/' + module_id, {status_alias: status_alias}, function (resp) {
                    this.refreshDataTable();
                });

            },

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/' + this.PREFIX, {
                    params: {

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc ? true : null,
                        mail_type: 'incoming',

                        agreement_id: this.filterData.agreement_id,
                        responsible_user_id: this.filterData.responsible_user_id,
                        sort_by_date_from: this.filterData.sort_by_date_from,
                        sort_by_date_to: this.filterData.sort_by_date_to,
                        shipment_type_id: this.filterData.shipment_type_id,
                        deadline_reply_at_from: this.filterData.deadline_reply_at_from,
                        deadline_reply_at_to: this.filterData.deadline_reply_at_to,

                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },

            deleteItem(id) {
                this.confirmDeleting((result) => {
                    this.async('delete', '/' + this.PREFIX + '/' + id, {}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },
            resolveRowClassVariant(item, type) {
                if (!item || type !== 'row') return
                if (this.checkedIds.includes(item.id)) return 'row-success';
            },
            getFileName(file){
                return file.display_name + '.' + file.file_name.split('.').pop();
            }

        },


    }
</script>

